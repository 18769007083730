import "index.scss"
import "syntax-highlighting.scss"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"
// import $ from 'jquery'


  var $hamburger = $(".hamburger");
  var $nav = $("nav");
  
  $hamburger.on("click", function(e) {
    $hamburger.toggleClass("is-active");
    $nav.toggleClass("hamburger-closed");
    $nav.toggleClass("hamburger-open");
  });


  var $openMenu = null;

  var showMenus = function(){
    $(".nav-menu-items ol li").removeClass("open")
    if ($openMenu == null) {
      // do nothing
    } else {
      $openMenu.addClass("open");
    }
  }

  $(".nav-menu-items ol").on("click", function(e){
    $ele = $(e.target);
    if ( $ele.is("span") ) {
      $ele = $ele.parent("li")
    }


    if ($ele.hasClass( "submenu" )){
      if ( $ele.is($openMenu) ) {
        $openMenu = null;
      } else {
        $openMenu = $ele;
      }
      showMenus();
    }
    e.stopPropagation();
  })

  $(document).on("click", function(){
    $openMenu = null;
    showMenus();
  })
